import { defineStore } from "pinia";
import type { UseFetchOptions } from "@vueuse/core";
import lodash from "lodash";
import useRefreshToken from "~/composables/useRefreshToken";

const { BASE_URL } = useRuntimeConfig().public;
const auth = useCookie<AuthResponse>("auth");
const { refreshToken } = useRefreshToken();

export const metricsStateInitialState = {
  totals: { totalBudget: 0, totalClicks: 0, totalImpressions: 0, totalCtr: 0, totalCpm: 0, totalCpc: 0 },
  data: [],
};

export const metricsStoreInitialState = {
  campaignId: "",
  campaignName: "",
  metricsState: lodash.cloneDeep(metricsStateInitialState),
  metricsBodyRequest: {},
  currentStep: 1,
  error: "",
  showError: false,
  loading: false,
  multiBrand: false,
};

export const useMetricsStore = defineStore("metricsStore", {
  state: (): MetricsStateType => metricsStoreInitialState,
  getters: {
    validStepOne(state) {
      return !lodash.isEqual(metricsStateInitialState, state.metricsState);
    },
  },
  actions: {
    setMetricsState(metrics: UploadMetricsType) {
      this.metricsState = metrics;
    },
    setMetricsBodyRequest(metrics: CampaignMetricsBackendType) {
      this.metricsBodyRequest = metrics;
    },
    setStep(step: number) {
      this.currentStep = step;
    },
    setError(error: string) {
      this.error = error;
    },
    setMultiBrand(multiBrand: boolean) {
      this.multiBrand = multiBrand;
    },
    resetMetricsState() {
      this.metricsState = lodash.cloneDeep(metricsStateInitialState);
    },
    resetMetricsBodyRequest() {
      this.metricsBodyRequest = {};
    },
    hideError() {
      this.showError = false;
      this.error = "";
    },
    async getCampaignData(campaignId: string) {
      if (this.campaignId !== "") {
        this.resetState();
      }
      this.loading = true;
      const requestConfig = {
        headers: {
          Authorization: `Bearer ${auth.value.access}`,
        },
        method: "GET",
      };
      const { data, error } = await useFetch<CampaignResponseType>(
        `${BASE_URL}/api/v2/campaigns/${campaignId}/`,
        requestConfig as UseFetchOptions,
      );
      if (error.value?.statusCode === 401) {
        await refreshToken();
      } else if (error.value?.statusCode === 404) {
        navigateTo("/campaigns/");
      } else if (data.value) {
        this.campaignId = data.value.id as string;
        this.campaignName = data.value?.campaign_display_name as string;
      }
      this.loading = false;
    },
    async sendMetrics(campaignId: string, update = false) {
      this.loading = true;
      if (this.error !== "") this.error = "";
      this.setStep(2);
      const requestSend = {
        headers: {
          Authorization: `Bearer ${auth.value.access}`,
        },
        body: { ...this.metricsBodyRequest, action: update ? "edit" : "insert" },
        method: "POST",
      };
      const { data, error } = await useFetch<{ message: string }, { data: { error: string } }>(
        `${BASE_URL}/api/v1/reports/upload_metrics/?campaign_id=${campaignId}`,
        requestSend as UseFetchOptions,
      );
      if (error.value) {
        this.showError = true;
        this.setError(error.value.data.error);
        this.setStep(1);
      } else if (data.value) {
        this.setStep(3);
      }
      this.loading = false;
    },
    resetState() {
      this.campaignId = "";
      this.campaignName = "";
      this.metricsState = lodash.cloneDeep(metricsStateInitialState);
      this.metricsBodyRequest = {};
      this.currentStep = 0;
      this.error = "";
      this.loading = false;
      this.multiBrand = false;
    },
  },
});
